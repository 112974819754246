import React from "react";
import { Link } from "gatsby";

const ContactUsBlock = ({ header, aichapter }) => {
    return (
        <div className="contact-us-block">
            <div className="text contact-us-block__title">
                <h2 className="text-h2 text-fw-medium text-clr-primary gap-4x">
                    {header || "We’d love to hear from you!"}
                </h2>
            </div>
            <div className="contact-us-block__content">
                <div className="mail">
                    <span>Drop us a line</span>
                    {aichapter ? (
                        <a href="mailto:aichapter@sedintechnologies.com" className="sdn-link ">
                            aichapter@sedintechnologies.com
                        </a>
                    ) : (
                        <a href="mailto:sales@sedintechnologies.com" className="sdn-link ">
                            sales@sedintechnologies.com
                        </a>
                    )}
                </div>
                <div>
                    <Link to="/contact/" className="sdn-cta-btn">
                        Talk To Us
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ContactUsBlock;
