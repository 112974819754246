import React from "react";
import Lottie from "react-lottie";
import * as subServiceHero from "../../../animations/newsroom.json";
import Layout from "../../../components/Layout";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";
import ContactUsBlock from "../../../components/ContactUsBlock";
import { useState } from "react";
import ArrowIcon from "../../../components/ArrowIcon";
import BlogGrid from "../../../components/BlogGrid";
import Seo from "../../../components/seo";
const NewsRoomPage = ({ location }) => {
    const [visible, setVisible] = useState(3);
    const newsData = useStaticQuery(graphql`
        {
            allContentfulNewsYear {
                nodes {
                    year
                    id
                    news {
                        id
                        title
                        featured
                        date
                        description {
                            description
                        }
                        logo {
                            gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                        }
                        blogImage {
                            gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                        }
                        externalUrl
                    }
                }
            }
        }
    `);

    const featuredData = newsData.allContentfulNewsYear.nodes.map((el) => {
        return el.news;
    });

    const FeaturedNews = featuredData.flat(1).filter((node) => node.featured);

    const showMoreItems = () => {
        setVisible((preValue) => preValue + 3);
    };

    return (
        <Layout>
            <Seo
                title={"Newsroom - Sedin"}
                description={
                    "Check out a range of news coverages, press releases, features and more about Sedin’s technology and business achievements. Start reading here."
                }
                url={location.href}
            />
            <section className="newsroom-hero">
                <div className="container ">
                    <div className="newsroom-hero__grid flex flex-jc-sb flex-ai-c">
                        <div className="newsroom-hero__text">
                            <h1 className="section-tag gap-2x">Newsroom</h1>
                            <h2 className="text-h1 text-fw-medium text-clr-primary gap-2x">
                                News & press
                            </h2>
                            <h3 className="text-p1 text-fw-regular text-clr-secondary">
                                The latest scoop about news, features, PR’s and every other business
                                buzz we make.
                            </h3>
                        </div>
                        <div className="newsroom-hero__image">
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: subServiceHero,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container container--mid-v2">
                    <BlogGrid relatedBlogs={FeaturedNews} newsroom />
                </div>
            </section>
            <section className="news">
                <div className="container container--mid-v2">
                    <h2 className="news__text text-h2 text-clr-primary text-fw-medium">
                        News <br></br> and announcements
                    </h2>
                    <div className="news__wrap">
                        {newsData.allContentfulNewsYear.nodes
                            .sort((a, b) => b.year - a.year)
                            .slice(0, visible)
                            .map((data, i) => (
                                <div key={data.id} className="news__grid ">
                                    <div className="news__grid-left-col">
                                        <h2 className="news__grid-item year text-h2 text-clr-primary text-fw-medium">
                                            {data.year}
                                        </h2>
                                    </div>
                                    <div className="news__grid-right-col">
                                        {data.news
                                            .filter((el) => !el.featured)
                                            .map((node) => (
                                                <div className="news__grid-item" key={node.id}>
                                                    <Link
                                                        to={node.externalUrl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="news__grid-item-card text-decor-none"
                                                    >
                                                        <div className="logo">
                                                            <GatsbyImage
                                                                image={node.logo.gatsbyImageData}
                                                                alt={node.title}
                                                                loading="lazy"
                                                            />
                                                        </div>

                                                        <div className="title text-p1 text-clr-primary text-fw-medium">
                                                            {node.title}
                                                        </div>

                                                        <div className="flex flex-jc-sb flex-ai-c">
                                                            <div className="text-clr-primary text-p5 text-fw-regulars">
                                                                {node.date}
                                                            </div>

                                                            <div className="arrow">
                                                                <ArrowIcon
                                                                    direction="right"
                                                                    theme={"blue"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                    </div>

                    {newsData.allContentfulNewsYear.nodes.length > 3 && (
                        <div className="news__btn">
                            <div
                                className="load-more-btn text-p4 text-fw-medium text-decor-none"
                                onClick={showMoreItems}
                            >
                                Load More
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <section className="about-contact">
                <div className="container container--last-v2">
                    <ContactUsBlock />
                </div>
            </section>
        </Layout>
    );
};

export default NewsRoomPage;
