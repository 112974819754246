import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const BlogGrid = ({ relatedBlogs, newsroom }) => {
    return (
        <section className="blog">
            <h2 className="text-h2 blog-header text-clr-primary text-fw-medium">
                {newsroom
                    ? "Featured stories"
                    : " Thought provoking insights for tech-powered business transformation"}
            </h2>
            <div className="blog-grid">
                {relatedBlogs?.map((el, i) => (
                    <div className="blog-grid__item" key={i}>
                        <GatsbyImage
                            className="blog-image"
                            image={el.blogImage.gatsbyImageData}
                            alt={el.title}
                            loading="lazy"
                        />
                        {newsroom && (
                            <GatsbyImage
                                image={el.logo.gatsbyImageData}
                                className="blog-logo"
                                alt={el.title}
                                loading="lazy"
                            />
                        )}
                        <div className="category-wrap">
                            {el.categories?.map((category, index) => (
                                <Link
                                    key={index}
                                    to={`/blog/${category.slug}/`}
                                    className="text-decor-none  category text-p5 text-fw-medium text-clr-primary"
                                >
                                    {category.categoryType}
                                </Link>
                            ))}
                        </div>
                        <p className="text-p3 gap-1x title text-clr-primary text-fw-medium">
                            {el.title}
                        </p>
                        <p className="text-p4 gap-1x description text-clr-secondary text-fw-regular">
                            {el.description.description}
                        </p>
                        <p className="text-p5 gap-1x  read text-fw-regular text-clr-secondary">
                            {el.readTime}
                        </p>
                        <Link
                            className="text-decor-none text-p3 text-fw-medium"
                            to={newsroom ? `${el.externalUrl}` : `/blog/${el.slug}/`}
                        >
                            Read more
                        </Link>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default BlogGrid;
